body {
  background-color: #F2F2F7;
  width: 100%;
}

.dashboardHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
}

.accountButton{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: #e1e1e1;
  margin-right: 10px;
}

.formSection {
  background-color: white;
  border-radius: 12px;
  margin: 20px;
  box-sizing: content-box;
}

.signInView {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 25%;
}

.signInInputs {
  background-color: white;
  border-radius: 12px;
  margin: 20px;
  box-sizing: content-box;
}

.signInInputs input {
  font-size: 16px;
  display: block;
  padding: 12px;
  padding-right: 15px;
  color: black;
  text-decoration: none;
  border: 0;
  background: none;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}

.signInInputs textarea {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  display: block;
  padding: 12px;
  padding-right: 15px;
  color: black;
  text-decoration: none;
  border: 0;
  background: none;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  resize: none;
}

.signInButtonBox {
  background-color: #5DBCD7;
  border-radius: 12px;
  margin: 20px;
  box-sizing: content-box;
}

.signInButton {
  font-weight: 500;
  font-size: 16px;
  padding: 12px;
  color: white;
  border: 0;
  width: 100%;
  background: none;
  box-sizing: border-box;
}

.signInLogo {
  width: 50%;
}

.formSectionLinks {
  background-color: white;
  border-radius: 12px;
  margin: 20px;
  box-sizing: content-box;
}

.formSection a {
  display: block;
  padding: 12px;
  padding-right: 15px;
  color: black;
  text-decoration: none;
  
}

.referralLink {
  display: block;
  padding: 12px;
  padding-right: 15px;
  color: #5DBCD7;
  text-decoration: none;
}

.copyButton {
  background: none;
  border: none;
  transition: 0.2s;
}

.copyButton :active {
  transform: scale(1.5);
  background-color: #F2F2F7;
  border-radius: 50px;
}

.material-symbols-outlined {
  color: #5DBCD7;
  font-variation-settings:
  'FILL' 1,
  'wght' 300,
  'GRAD' 200,
  'opsz' 48
}

.navigationLink {
  align-items: center;
  justify-content: center;
}

.QRLink {
  display: block;
  padding: 12px;
  padding-right: 15px;
  color: black;
  text-decoration: none;
  
}

.overviewBox {
  display: flex;
  flex-direction: column;
  margin: 20px;
  background-color: white;
  border-radius: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.info-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
  position: relative;
  width: 50%;
  margin: 10px;
}

.info-title {
  font-size: 0.75rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  align-self: flex-start;
}

.info-value {
  font-size: 1.25rem;
  font-weight: 700;
  align-self: flex-end;
}

.info-padding {
  height: 15px;
}

.dividerHorizontal {
  height: 0.3px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.dividerVertical {
  width: 0.3px;
  height: calc(100%-10px);
  background-color: rgba(0, 0, 0, 0.2);
}

.clientRow {
  display: flex;
}

.signInOrBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.signInOrBlock p {
  margin: 10px;
  color: rgba(0, 0, 0, 0.2);
}

.registerButtonBox {
  background-color: #5DBCD7;
  border-radius: 12px;
  margin: 20px;
  box-sizing: content-box;
}

.registerButton {
  font-size: 16px;
  padding: 12px;
  color: white;
  border: 0;
  width: 100%;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
}

.errorMessage {
  display: flex;
  align-items: flex-start;
  margin: 0;
  margin-left: 25px;
  margin-right: 25px;
  font-size: small;
  color: red;
}

.codeTitle {
  display: flex;
  align-items: flex-start;
  margin-left: 25px;
  margin-top: 20px;
  font-size:medium;
}

.application-submitted-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 40px;
  text-align: center;
}

.application-submitted-view h3 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.application-submitted-view p {
  font-size: 20px;
  line-height: 1.5;
  max-width: 500px;
  margin-bottom: 30px;
}

.material-symbols-outlined {
  font-size: 60px;
}

.clientRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.clientRow p {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.clientRow > p:first-child {
  flex: 1;
  text-align: left;
  margin-right: 10px;
}

.clientRow > p:nth-child(2) {
  text-align: center;
}

.clientRow > p:last-child {
  flex: 1;
  text-align: right;
  margin-left: 10px;
}

.clientRowKey {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 30px;
  color: rgba(0, 0, 0, 0.6);
}

.clientRowKey p {
  flex: 1;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clientRowKey p:first-child {
  text-align: left;
}

.clientRowKey p:last-child {
  text-align: right;
}

.clientSection {
  background-color: white;
  border-radius: 12px;
  margin: 0px 20px;
  box-sizing: content-box;
}

.noClientsMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}

.loadScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}